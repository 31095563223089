import React from "react"
import { Link } from "gatsby"

const BlogPostMetaArea = ({ category, date, tags }) => {
  return (
    <div className="entry-meta mb-4">
      <ul>
        <li>
          <i className="bx bx-purchase-tag" />
          <span>Tags</span>
          {
            tags.map((tag, index) => (
              <React.Fragment key={index}>
                <Link className={"neutral-link-optional-color"} to={`/journal/tag/${tag.toLowerCase()}/`}>{tag}</Link>
                {index < tags.length - 1 && ", "}
              </React.Fragment>
            ))
          }
        </li>

        <li>
          <i className="bx bx-calendar" />
          <span>Erstellt</span>
          <Link className={"neutral-link-optional-color"} to="#">
            {date}
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default BlogPostMetaArea
